<template>
    <div class="page page--faq faq">
        <div class="page__block">
            <h2>{{$t('faq.head')}}</h2>
            <ol class="list list--ordered">
                <li v-for="(item,index) in (Object.keys($i18n.messages[$i18n.locale].faq).length -2)/2"
                    :key="'link--'+item">
                    <a :href="'#faq--'+index">{{$t('faq.title'+(index+1))}}</a>
                </li>
            </ol>
        </div>
        <div :id="'faq--'+index" class="page__block"
             v-for="(item,index) in (Object.keys($i18n.messages[$i18n.locale].faq).length -2)/2"
             :key="item">
            <h2>{{index + 1}}. {{$t('faq.title'+(index + 1))}}</h2>
            <p>{{$t('faq.text'+(index+1))}}</p>
        </div>
        <div class="page__block" v-html="$t('faq.privacy')">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Faq",

        mounted() {
            window.scrollTo(0, 0);
        }
    }
</script>